<form [formGroup]="form" (ngSubmit)="onSubmit()">

<mat-dialog-content lyAlignContent="center" class="mat-typography">
    <p>Send Message</p>

    <mat-form-field appearance="fill">
        <mat-label>Type your message here</mat-label>
        <textarea rows="7" matInput formControlName="message"></textarea>


        <app-field-error-display [displayError]="isFieldValid(form, 'message')"
        errorMsg="Client contact number required">
        </app-field-error-display>
    </mat-form-field>

    <div>
        <div class="row">
            <div *ngFor="let i of [1,1]" class="col-md-6 p-2">
                <mat-form-field>
                    <ngx-mat-file-input (ngModelChange)="uploadFile($event,'images')"
                                        formControlName="imagez" placeholder="Photo attachment"
                                        [accept]="' .png, .jpg , .jpeg'"
                    >

                    </ngx-mat-file-input>
                    <mat-icon matSuffix>attach_file</mat-icon>


                </mat-form-field>

            </div>

        </div>
    </div>
</mat-dialog-content>
<div class="mat-dialog-actions">
    <!--  <button mat-button (click)="onNoClick()">No Thanks</button>-->
    <button type="submit" mat-button class="blue3 btn-block"
            [mat-dialog-close]="form.value" cdkFocusInitial>Send
    </button>
</div>
</form>
