




        <div #scrollMe class="sidebar-wrapper">


            <div style="margin-top: 10px">
                <img src="/assets/img/logo2.png" style="height: 55px"/>
            </div>


            <div class="user">
                <div class="photo">
                    <img style="width: 100%"  (error)="data.photo = './assets/img/avatar.png'" src="{{data.photo}}" />
                </div>
                <div class="user-info">
                    <a *ngIf="type === 'Plumber'" routerLink="/profile/{{uid}}"   class="collapsed">
                        <span>
                            {{usr}}

                        </span>
                    </a>



                    <a *ngIf="type === 'manufacture'"   class="collapsed">
                        <span>
                            {{comp}}

                        </span>
                    </a>


                    <a *ngIf="type === 'province'" class="collapsed">
                        <span>
                            {{ comp }}

                        </span>
                    </a>


                    <a *ngIf="type === 'municipal'" class="collapsed">
                        <span>
                            {{ comp }}

                        </span>
                    </a>


                    <a *ngIf="type === 'insurance'"   class="collapsed">
                        <span>
                            {{comp}}

                        </span>
                    </a>



                    <a *ngIf="type === 'admin' "   class="collapsed">
                        <span>
                          Admin

                        </span>
                    </a>

                    <a routerLink="/profile/{{uid}}"  *ngIf="type === 'Company'"   class="collapsed">
                        <span>
                            {{usr}}

                        </span>
                    </a>

                </div>
            </div>


            <ul class="nav">

                <li *ngIf="type === 'Plumber'|| type === 'Company'" class="nav-item" routerLinkActive="active">

                    <a [routerLink]="'/drafts'" class="nav-link">
                        <i class="material-icons">save_as</i>

                        <p>Drafts</p>

                    </a>
                </li>

                <li class="nav-item" routerLinkActive="active">
                    <!--If is a single link-->
                    <a *ngIf="type === 'Plumber'|| type === 'Company' &&  !data.parent" [routerLink]="'/submit'"
                       class="nav-link">
                        <i class="material-icons">add_task</i>
                        <p>Create COC</p>
                    </a>
                </li>


                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/service-submit'"  *ngIf="type === 'Plumber'|| type === 'Company' &&  !data.parent"  class="nav-link">
                        <i class="material-icons">miscellaneous_services</i>
                        <p>Service Form COC</p>
                    </a>
                </li>



<!--                <li routerLinkActive="active"  class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a [routerLink]="'/noc'"  *ngIf="type === 'Plumber'|| type === 'Company' &&  !data.parent"  class="nav-link">-->
<!--                        <i class="material-icons">post_add</i>-->
<!--                        <p>Notice Of Non Compliance  </p>-->
<!--                    </a>-->
<!--                </li>-->




                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/sale-of-property'"  *ngIf="type === 'Plumber'|| type === 'Company' &&  !data.parent"  class="nav-link">
                        <i class="material-icons">post_add</i>
                        <p>Sale of Property  COC</p>
                    </a>
                </li>


                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a data-toggle="collapse" href="#history" (click)="updatePS()" *ngIf="type === 'Plumber'   &&  !data.parent "  class="nav-link">
                        <i class="material-icons">history</i>
                        <p>COC History<b class="caret"></b></p>

                    </a>
                </li>


                <div id="history" class="collapse" >
                    <ul class="nav">
                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/submissions'"      class="nav-link">
                                <p>COC History</p>

                            </a>
                        </li>

                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/service-submissions'"      class="nav-link">
                                <p>Service Form  COC</p>

                            </a>
                        </li>


                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/noc-submissions'"      class="nav-link">
                                <p>Notice Of Non Compliance  </p>

                            </a>
                        </li>

                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/sop-submissions'"      class="nav-link">
                                <p >Sale of Property  COC</p>

                            </a>
                        </li>


                    </ul>


                </div>






                <li routerLinkActive="active"  class="nav-item">

                    <a href="#approved" data-toggle="collapse" (click)="updatePS()"  *ngIf="type === 'Company'"  class="nav-link">
                        <i class="material-icons">check_circle_outline</i>
                        <p>Approved COCs<b class="caret"></b></p>

                    </a>
                </li>


                <div id="approved" class="collapse" >
                    <ul class="nav">
                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/submissions'"      class="nav-link">
                                <p>COC History</p>

                            </a>
                        </li>

                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/service-submissions'"     class="nav-link">
                                <p>Service Forms COC</p>

                            </a>
                        </li>


                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/noc-submissions'"     class="nav-link">
                                <p>Notice Of Non Compliance</p>

                            </a>
                        </li>



                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/sop-submissions'"     class="nav-link">
                                <p>Sale of property COC</p>

                            </a>
                        </li>


                    </ul>


                </div>









                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a data-toggle="collapse" (click)="updatePS()" href="#pending" *ngIf="type === 'Company'"  class="nav-link">
                        <i class="material-icons">hourglass_empty</i>
                        <p>Pending COCs<b class="caret"></b></p>
                    </a>
                </li>


                <div id="pending" class="collapse" >
                    <ul class="nav">
                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/approve-submissions'"   class="nav-link">
                                <p>COC History</p>

                            </a>
                        </li>

                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/approve-service-submissions'"    class="nav-link">
                                <p>Service Form History</p>

                            </a>
                        </li>

                    </ul>


                </div>






                <li routerLinkActive="active"  class="nav-item ">
                    <!--If is a single link-->
                    <a  data-toggle="collapse" (click)="updatePS()" href="#all"  *ngIf="type=='admin' || type=='insurance' || type=='manufacture'"  class="nav-link">
                        <i class="material-icons">check_circle_outline</i>
                        <p>COC History<b class="caret"></b></p>
                    </a>
                </li>






                <div id="all" class="collapse" >
                    <ul class="nav">
                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/submissions'"     class="nav-link">
                                <p>COC History</p>

                            </a>
                        </li>

                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/service-submissions'"     class="nav-link">
                                <p>Service Form COC </p>

                            </a>
                        </li>

                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/noc-submissions'"     class="nav-link">
                                <p>Notice Of Non Compliance </p>

                            </a>
                        </li>


                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/sop-submissions'"     class="nav-link">
                                <p>Sale of Property COC</p>

                            </a>
                        </li>





                    </ul>


                </div>




                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/companies/manufacture'" *ngIf="comp === 'admin'" class="nav-link">
                        <i class="material-icons">business</i>
                        <p>Manufactures</p>
                    </a>
                </li>


                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/companies/insurance'" *ngIf="comp === 'admin'" class="nav-link">
                        <i class="material-icons">business</i>
                        <p>Insurance</p>
                    </a>
                </li>


                <li class="nav-item" routerLinkActive="active">
                    <!--If is a single link-->
                    <a *ngIf="comp === 'admin'" [routerLink]="'/organisations'" class="nav-link">
                        <i class="material-icons">business</i>
                        <p>Organisations</p>
                    </a>
                </li>

                <li class="nav-item" routerLinkActive="active">
                    <!--If is a single link-->
                    <a *ngIf="comp === 'admin'" [routerLink]="'/companies/province'" class="nav-link">
                        <i class="material-icons">pin_drop</i>
                        <p>Province Admin</p>
                    </a>
                </li>


                <li class="nav-item" routerLinkActive="active">
                    <!--If is a single link-->
                    <a *ngIf="comp === 'admin'" [routerLink]="'/companies/municipal'" class="nav-link">
                        <i class="material-icons">pin_drop</i>
                        <p>Municipal Admin</p>
                    </a>
                </li>


                <li class="nav-item" routerLinkActive="active">
                    <!--If is a single link-->
                    <a [routerLink]="'/submissions-source'" *ngIf="comp === 'admin'" class="nav-link">
                        <i class="material-icons">campaign</i>
                        <p>Marketing</p>
                    </a>
                </li>


                <li class="nav-item" routerLinkActive="active">
                    <!--If is a single link-->
                    <a [routerLink]="'/downloads'" *ngIf="comp === 'admin'" class="nav-link">
                        <i class="material-icons">download</i>

                        <p>Downloads</p>
                    </a>
                </li>



                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/invoices'" *ngIf="comp === 'admin'" class="nav-link">
                        <i class="material-icons">receipt</i>
                        <p>Invoices</p>
                    </a>
                </li>


                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/add-accounts'" *ngIf="comp === 'admin' || type === 'Company'" class="nav-link">
                        <i class="material-icons">person_add</i>
                        <p>Add accounts</p>
                    </a>
                </li>

                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/users'"  *ngIf="type === 'Company'"  class="nav-link">
                        <i class="material-icons">group</i>
                        <p>Staff Members  <mat-icon [matBadge]="count" class="material-icons" color="primary"></mat-icon></p>
                    </a>
                </li>



                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="'/all-users'" *ngIf="comp === 'admin'"  class="nav-link">
                        <i class="material-icons">group</i>
                        <p>Plumbers</p>
                    </a>
                </li>


                <li routerLinkActive="active" *ngIf="data.parent"  class="nav-item">
                    <!--If is a single link-->
                    <a  class="nav-link" routerLink="/chat">
                        <i class="material-icons">mail</i>
                        <p>Inbox  <mat-icon [matBadge]="count" class="material-icons" color="primary"></mat-icon></p>
                    </a>
                </li>


<!--                <li routerLinkActive="active"  *ngIf="type == 'Plumber' || type === 'Company'" class="nav-item">-->
<!--                    &lt;!&ndash;If is a single link&ndash;&gt;-->
<!--                    <a routerLink="/resources" class="nav-link" >-->
<!--                        <i class="material-icons">book</i>-->
<!--                        <p>Resources</p>-->
<!--                    </a>-->
<!--                </li>-->


                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a  data-toggle="collapse" (click)="updatePS()" href="#nonc" *ngIf="type == 'Plumber' || type === 'Company'"  class="nav-link">
                        <i class="material-icons">check_circle_outline</i>
                        <p>Non Compliance <b class="caret"></b></p>
                    </a>
                </li>


                <div id="nonc" class="collapse" >
                    <ul class="nav">


                        <li routerLinkActive="active"  class="nav-item">

                            <a  (click)="alert()"    class="nav-link">
                                <p>Notice of non compliance PDF</p>

                            </a>
                        </li>


                        <li routerLinkActive="active"  class="nav-item">

                            <a [routerLink]="'/noc'"     class="nav-link">
                                <p>General non compliance form</p>

                            </a>
                        </li>





                    </ul>


                </div>




                <li *ngIf="type === 'Plumber'" class="nav-item" routerLinkActive="active">
                    <!--If is a single link-->
                    <a class="nav-link" href="https://ipa.waccsa.co.za/register" target="_blank">
                        <i class="material-icons">group</i>
                        <p>Join IPA</p>
                    </a>
                </li>

                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a  class="nav-link" href="https://www.waccsa.co.za/support/" target="_blank">
                        <i class="material-icons">contact_support</i>
                        <p>Visit Support Page</p>
                    </a>
                </li>

                <li routerLinkActive="active" routerLink="/analytics-home"  *ngIf="comp === 'admin' " class="nav-item">
                    <!--If is a single link-->
                    <a  class="nav-link" >
                        <i class="material-icons">bar_chart</i>
                        <p>Analytics</p>
                    </a>
                </li>





                <li routerLinkActive="active"  class="nav-item">
                    <!--If is a single link-->
                    <a class="nav-link" (click)="logOut()">
                        <i class="material-icons">power_settings_new</i>
                        <p>
                            Logout
                        </p>
                    </a>
                </li>


            </ul>

<!--            <div class="example-button-container" style="position: absolute; right: 10px; bottom: 50%">-->
<!--                <button color="warn" mat-mini-fab disabled aria-label="Example icon button with a home icon">-->
<!--                    <mat-icon color="accent">arrow_circle_down</mat-icon>-->
<!--                </button>-->
<!--            </div>-->
        </div>
